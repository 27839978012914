<template>
  <header class="header">
    <h1 class="logo">
      <NuxtImg
        class="image"
        to="/generate"
        provider="uploadcare"
        src="108071a7-d311-4bf8-adb2-765deffdf2d9"
        alt="MyRenderer Logo"
      />
      v1.1.3
    </h1>
    <div class="info-wrapper">
      <div
        v-if="user?.myRendererStatus !== 'ENABLED' && !user?.isBilateral && isPlanButtonAllowed"
        class="start-plan"
      >
        <v-btn
          color="primary-light"
          size="small"
          data-evt="gnav-clickstream-mailform-link-global-upgrade-button"
          @click="openEditDialog"
        >
          プランの変更
        </v-btn>
      </div>
      <div
        v-if="user?.stripeCustomerId && !user?.isBilateral && user?.myRendererStatus === 'ENABLED' && isPlanButtonAllowed"
        class="start-plan"
      >
        <v-btn
          color="primary-light"
          size="small"
          @click="handlePortalClick"
        >
          お支払い情報
        </v-btn>
      </div>
      <div
        v-if="isPlanButtonAllowed"
        class="download-count-display"
      >
        <v-btn
          class="btn"
          color="white"
          variant="outlined"
          size="small"
        >
          <v-icon>
            mdi-download
          </v-icon>
          <v-tooltip
            activator="parent"
            location="bottom"
          >
            <p>
              今月のダウンロード数 {{ user?.downloadCount }} 回
            </p>
          </v-tooltip>
          {{ user?.downloadCount }} 回
        </v-btn>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { isPlanButtonAllowedOnRoute } from '@/utils/plan-button-route-check'

const route = useRoute()
const isPlanButtonAllowed: boolean = isPlanButtonAllowedOnRoute(['/consent', '/verify', '/profile', '/plan', '/subscriptions/success'], route.path)
const accountStore = useAccountStore()
const user = computed(() => accountStore.user)
const portal = ref<string>('')
const dialogStore = useDialogStore()
const openEditDialog = () => {
  dialogStore.openDialog('UpgradePlanDialog')
}

const getBillingPortal = async () => {
  if (user.value?.stripeCustomerId && !user.value?.isBilateral && isPlanButtonAllowed) {
    const response = await useCustomDollarFetch('/api/stripe/portal')
    if (response) {
      portal.value = response
    }
  }
}

const handlePortalClick = async () => {
  await getBillingPortal()
  if (portal.value) {
    window.open(portal.value, '_blank')
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: fit-content;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .logo {
    gap: 0.5rem;
    height: 2rem;
    font-size: 1rem;
    width: fit-content;
    display: flex;
    align-items: center;

    @include media(sm) {
      font-size: 0.685rem;
    }

    > .image {
      width: auto;
      height: 1.5rem;

      @include media(sm) {
        height: 0.685rem;
      }
    }
  }

  > .info-wrapper {
    display: flex;
    gap: 0.5rem;
  }
}
</style>
